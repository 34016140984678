import React, { useState, useEffect } from "react";
import Portfolio from "../Items/Portfolio";

const filters = [
  {
    id: 1,
    name: "All Projects",
  },
  {
    id: 2,
    name: "Front-End",
  },
  {
    id: 3,
    name: "WordPress",
  },
  {
    id: 4,
    name: "React",
  },
  {
    id: 5,
    name: "Laravel",
  },
];

const allData = [
  {
    id: 1,
    name: "Charm",
    category: ["wordpress", "laravel", "front-end"],
    image: "images/portfolio/charm.png",
    slug: "https://charm.clinic/",
  },
  {
    id: 2,
    name: "200riyals",
    category: ["wordpress", "front-end"],
    image: "images/portfolio/200r.png",
    slug: "https://200riyals.com/",
  },
  {
    id: 3,
    name: "Roqay",
    category: ["wordpress"],
    image: "images/portfolio/roqay.png",
    slug: "https://roqay.com/",
  },
  {
    id: 4,
    name: "Seos",
    category: ["front-end"],
    image: "images/portfolio/seos.png",
    slug: "http://seose.22web.org/",
  },
  {
    id: 5,
    name: "Eventab",
    category: ["laravel"],
    image: "images/portfolio/evn.png",
    slug: "https://eventap.events/ar/dashboard",
  },
  {
    id: 6,
    name: "Bayet",
    category: ["react"],
    image: "images/portfolio/byet.png",
    slug: "https://bayt-three.vercel.app",
  },
  {
    id: 7,
    name: "Khyam",
    category: ["wordpress", "front-end"],
    image: "images/portfolio/kyma.png",
    slug: "https://kutob.22web.org",
  }
];

function Portfolios() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(3);
  const [activeFilter, setActiveFilter] = useState("all projects");
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
    setVisibleItems(getAllItems);
  }, [getAllItems]);

  const handleChange = (e) => {
    const targetFilter = e.target.value ? e.target.value.toLowerCase() : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);

    let filteredItems;
    if (targetFilter === filters[0].name.toLowerCase()) {
      filteredItems = getAllItems;
    } else {
      filteredItems = getAllItems.filter((data) =>
        data.category.includes(targetFilter)
      );
    }
    setVisibleItems(filteredItems);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    setDataVisibleCount(dataVisibleCount + 3);
  };

  return (
    <>
      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.name.toLowerCase() === activeFilter
                ? "list-inline-item current"
                : "list-inline-item"
            }
            key={filter.id}
            onClick={handleChange}
          >
            {filter.name}
          </li>
        ))}
      </ul>

      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile form-control"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="row portfolio-wrapper">
        {visibleItems.slice(0, dataVisibleCount).map((item) => (
          <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
            <Portfolio portfolio={item} />
          </div>
        ))}
      </div>

      {dataVisibleCount >= getAllItems.length ? null : (
        <div className="load-more text-center mt-4">
          <a
            href="#!"
            className="btn btn-default"
            onClick={(e) => handleLoadmore(e)}
          >
            <i className="fas fa-circle-notch"></i> Load more
          </a>
        </div>
      )}
    </>
  );
}

export default Portfolios;
