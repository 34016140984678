import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  // {
  //   id: 1,
  //   year: "2018 - 2014",
  //   degree: "Bachelor’s Degree",
  //   content:
  //     "Bachelor's degree in Computer Science.",
  // },
  // {
  //   id: 2,
  //   year: "2018",
  //   degree: "Cambridge Diploma",
  //   content:
  //     "Diploma in Ethical Hacking.",
  // },
  {
    id: 1,
    year: "2019 - 2020",
    degree: "ElRyad",
    content:
      "Frontend programmer with wordpress",
  },
  {
    id: 2,
    year: "2020 - 2021",
    degree: "Neop",
    content:
      "Frontend programmer with wordpress",
  },
  {
    id: 3,
    year: "2021 - 2022",
    degree: "200Rieals(KSA)",
    content:
      "PHP Developer , Full-stack",
  },
  {
    id: 4,
    year: "2022 - ...",
    degree: "Roqay",
    content:
      "React Framework , Full-stack",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
