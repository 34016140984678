import React from "react";

const aboutData = {
  cvpath: "media/cv.pdf",
  image: "images/logo.jpg",
  name: "Said Mahmoud",
  location: "Egypt, EG",
  birthday: "6 May, 1997",
  email: "saidd.mahmoud4@gmail.com",
  aboutMe:
    "My beginning was in the field of front-end, since the beginning of the youth stage and I love programming a lot and have a great passion for it and learn it very quickly, and since 2018, I developed myself in back-end and wordpress, and I also have works in Python, Laravel and React.",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          <div className="col-md-12">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            {/* <p className="mb-0">
              Birthday: <span className="text-dark">{aboutData.birthday}</span>
            </p> */}
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div>
        </div>
        <a href={aboutData.cvpath} className="btn btn-default mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </a>
      </div>
    </div>
  );
}

export default About;
