import React from "react";
import Service from "../Items/Service";

const servicesData = [
  {
    id: 1,
    name: "Development",
    content: "Programming and creating websites, in all its details and with SEO.",
  },
  {
    id: 2,
    name: "Design",
    content: "New designs to match the devices.",
  },
  {
    id: 3,
    name: "Desktop App",
    content: "Tools and software to help you with your tasks.",
  },
  {
    id: 4,
    name: "SEO",
    content: "Full interest in the appearance of your site in the first searches of Google.",
  },
  {
    id: 5,
    name: "Copy Write",
    content: "Writing appropriate content to attract customers.",
  },
  {
    id: 6,
    name: "Courses",
    content: "Learn with us and get free courses and books.",
  },
];

function Services() {
  return (
    <div className="row -mt-20">
      {servicesData.map((service) => (
        <div className="col-md-4 col-sm-6 mt-20" key={service.id}>
          <Service service={service} />
        </div>
      ))}
    </div>
  );
}

export default Services;
